import React from "react";
import logo from "../../Images/nova.jpg"


export default function Header() {
    return (

            <div className="h-14 py-3 flex justify-center lg:h-16">
                <img src={logo} className="h-full"/>
            </div>
    )
    }