import { Card, CardContent, CardMedia } from "@mui/material";
import React from "react";
import coding from "../../Images/coding.jpg";
import processes from "../../Images/processes.jpg";
import consulting from "../../Images/consulting.jpg";

export default function Services() {
  return (
    <div className="pt-16 lg:pt-32">
      <h1 className="text-[#09CBFA] text-2xl text-left lg:text-center lg:text-3xl">
        What We Do
      </h1>
      <div className="lg:flex lg:justify-center">
        <Card
          sx={{ backgroundColor: "#1C333A" }}
          className="mt-5 lg:w-1/4 lg:mx-4"
        >
          <CardMedia sx={{ height: 300 }} image={coding} />
          <CardContent>
            <h2 className="text-[#09CBFA] text-2xl text-left">
              Software Development
            </h2>
            <p className="text-white pt-3 leading-relaxed">
              Whether you're imagining a modern, streamlined website, in search
              of custom software solutions designed for your specific business
              needs, or looking to create a groundbreaking app, our mission is
              to transform your concepts into reality. Our team blends
              expertise, innovation, and a focus on our clients to guarantee
              that your digital endeavors not only fulfill but surpass your
              expectations.
            </p>
          </CardContent>
        </Card>
        <Card
          sx={{ backgroundColor: "#1C333A" }}
          className="mt-5 lg:w-1/4 lg:mx-4"
        >
          <CardMedia sx={{ height: 300 }} image={processes} />
          <CardContent>
            <h2 className="text-[#09CBFA] text-2xl text-left">
              Digital Marketing
            </h2>
            <p className="text-white pt-3 leading-relaxed">
              Whether you're aiming to elevate your online presence, engage more
              effectively with your audience, or boost your brand's digital
              footprint, our team is dedicated to crafting strategic digital
              marketing solutions for you. We combine data-driven insights,
              creative strategy, and a deep understanding of digital trends to
              ensure that your brand not only connects with your audience but
              also stands out in the digital landscape.
            </p>
          </CardContent>
        </Card>
        <Card
          sx={{ backgroundColor: "#1C333A" }}
          className="mt-5 lg:w-1/4 lg:mx-4"
        >
          <CardMedia sx={{ height: 300 }} image={consulting} />
          <CardContent>
            <h2 className="text-[#09CBFA] text-2xl text-left">
              Technology Consulting
            </h2>
            <p className="text-white pt-3 leading-relaxed">
              Whether you're seeking to leverage the latest technological
              advancements or navigate the complexities of digital
              transformation, our technology consulting services are tailored to
              guide you through every step. Our team of experts employs a
              strategic, insight-driven approach to empower your business with
              innovative solutions, ensuring you stay ahead in the ever-evolving
              tech landscape while delivering tangible results.
            </p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
