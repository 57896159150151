import React from "react";
import logo from "../../Images/nova.jpg";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";

export default function Header() {
  return (
    <div className="flex flex-col h-screen">
      {" "}
      {/* This div acts as a flex container */}
      {/* Header section */}
      <div className="h-14 py-3 flex justify-center lg:h-16">
        <img src={logo} className="h-full" />
      </div>
      {/* Main content section */}
      <div className="flex-1 overflow-auto flex flex-col align-middle justify-center">
        {" "}
        {/* flex-1 makes this div fill available space */}
        <h1 className="text-white text-4xl text-center lg:text-6xl">
          Innovative Solutions
        </h1>
        <h1 className="text-[#09CBFA] text-4xl text-center lg:text-6xl">
          Business Growth
        </h1>
        <Button
          sx={{
            borderColor: "#ffffff",
            color: "#ffffff",
            borderWidth: 1,
            borderStyle: "solid",
            width: "75%",
            mx: "auto",
            my: "2rem",
            "@media screen and (min-width: 1024px)": {
              width: "auto", // Width adapts to content size on large screens
              px: "2.5rem",
            },
          }} onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        >
          Book a Consultation Today
        </Button>
      </div>
    </div>
  );
}
