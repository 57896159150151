import React from 'react';
import emailjs from 'emailjs-com';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button } from '@mui/material';

function ConsultationForm() {
  const { control, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const emailParams = {
      from_name: data.name,
      reply_to: data.email,
      message: data.message,
    };

    emailjs.send('service_06n5n2d', 'template_szwulfx', emailParams, '69YWfZsZqaFa47D9S')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
        reset(); // This will reset the form fields after a successful submission
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send the message, please try again.');
      });
  };

  return (
    <div className="pt-12 pb-6 lg:px-64 lg:pt-24 lg:pb-12">
      <div className="py-3">
        <h1 className="text-[#09CBFA] text-2xl text-left lg:text-center lg:text-3xl">
          Book a Consultation
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                InputLabelProps={{ style: { color: "#fff" } }}
                InputProps={{
                  style: {
                    backgroundColor: "#1C333A",
                    color: "#fff",
                  },
                }}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                fullWidth
                margin="normal"
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                InputLabelProps={{ style: { color: "#fff" } }}
                InputProps={{
                  style: {
                    backgroundColor: "#1C333A",
                    color: "#fff",
                  },
                }}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                fullWidth
                margin="normal"
              />
            )}
          />

          <Controller
            name="message"
            control={control}
            defaultValue=""
            rules={{ required: "Message is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Message"
                variant="outlined"
                InputLabelProps={{ style: { color: "#fff" } }}
                InputProps={{
                  style: {
                    backgroundColor: "#1C333A",
                    color: "#fff",
                  },
                }}
                error={!!errors.message}
                helperText={errors.message ? errors.message.message : ""}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#09CBFA",
              color: "#fff",
              width: "100%",
              "@media screen and (min-width: 1024px)": {
                width: "auto",
                px: "2.5rem",
                mx: "auto",
                display: "block",
              },
            }}
          >
            Send Message
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ConsultationForm;
