import './App.css';
import About from './Components/About/About';
import ConsultationForm from './Components/ConsultationForm/ConsultationForm';
import Header from './Components/Header/Header';
import Hero from './Components/Hero/Hero';
import Services from './Components/Services/Services';
import Footer from "./Components/Footer/Footer"

function App() {
  return (
    <div className="px-5 py-4 bg-[#001318] lg:px-16">
      <Header />
      <About />
      <Services />
      <ConsultationForm />
      <Footer />
    </div>
  );
}

export default App;
